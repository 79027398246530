<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Дашборд</h1>
      </div>
      <div class="page-header__ell">
        <b-form-group>
          <b-form-radio-group
            v-model="duration"
            @change="log"
            :options="[
                { text: 'сегодня' , value: 'day'   },
                { text: 'неделю'  , value: 'week'  },
                { text: 'месяц'   , value: 'month' }
              ]"
            name="radios-btn"
            buttons
            button-variant="primary"
          ></b-form-radio-group>
        </b-form-group>
      </div>
    </div>
    <div class="dashboard">
      <div class="dashboard__block">
        <div v-if="statistic[duration]" class="dashboard__status">
          <div class="dashboard__status-ell mb-md">
            <router-link :to="{ name: $routeName.CLIENT_LIST }" class="card-link">
              <div class="status-block">
                <img class="status-block__icon" src="@img/icon/ic-status-1.png" alt="">
                <div class="status-block__content">
                  <div class="status-block__val">+{{statistic[duration].user}}</div>
                  <div class="status-block__prop">тренирующихся</div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="dashboard__status-ell mb-md">
            <router-link :to="{ name: $routeName.TRAINER_LIST }" class="card-link">
              <div class="status-block">
                <img class="status-block__icon" src="@img/icon/ic-status-2.png" alt="">
                <div class="status-block__content">
                  <div class="status-block__val">+{{statistic[duration].trainer}}</div>
                  <div class="status-block__prop">тренеров</div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="dashboard__status-ell mb-md">
            <router-link :to="{ name: $routeName.CLUB_LIST }" class="card-link">
              <div class="status-block">
                <img class="status-block__icon" src="@img/icon/ic-status-3.png" alt="">
                <div class="status-block__content">
                  <div class="status-block__val">+{{statistic[duration].club}}</div>
                  <div class="status-block__prop">клубов</div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="dashboard__status-ell mb-md">
            <router-link :to="{ name: $routeName.TRAINING_LIST }" class="card-link">
              <div class="status-block">
                <img class="status-block__icon" src="@img/icon/ic-status-4.png" alt="">
                <div class="status-block__content">
                  <div class="status-block__val">+{{statistic[duration].event}}</div>
                  <div class="status-block__prop">записей на тренировки</div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="dashboard__block">
            <h3 class="dashboard__title">Последние оплаты <b-icon icon="arrow-right"></b-icon></h3>
            <b-table
              thead-class="d-none"
              :items="paymentList"
              borderless
              striped
            >
              <template #cell(status)="data">
                <span v-if="data.item.status === 'Оплачено'" class="status status--green"></span>
              </template>
            </b-table>
          </div>
        </div>
        <div class="col-6">
          <div class="dashboard__block">
            <h3 class="dashboard__title">Последние регистрации <b-icon icon="arrow-right"></b-icon></h3>
            <b-table
              thead-class="d-none"
              :items="registrationList"
              borderless
              striped
            >
            </b-table>
          </div>
        </div>
      </div>
      <div class="dashboard__block">
        <h3 class="dashboard__title">Последние заведённые клубы <b-icon icon="arrow-right"></b-icon></h3>
        <div class="row">
          <template v-for="(club, index) in clubList">
            <div class="col-12 col-md-4 mb-md" :key="index">
              <router-link :to="{ name: $routeName.CLUB_VIEW, params: { clubId: club.id }}" class="card-link">
                <CardClub
                  :satus="club.satus"
                  :img="club.img"
                  :name="club.name"
                  :text="club.text"
                  :tags="club.tags"
                />
              </router-link>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import CardClub from '@component/Module/CardClub';

export default {
  name: "Dashboard",
  components: {
    CardClub
  },
  data() {
    return {
      duration: 'day',
      statistic: {
        day   : false,
        week  : false,
        month : false,
      },
      paymentList: [],
      registrationList: [],
      clubList: []
    };
  },
  methods: {
    log(arg) {
      this.loadStats(arg);
    },
    
    loadStats(duration){
      let _duration =  duration;
      if(this.statistic[_duration]) {
        return;
      }
      
      let dateStart = DateTime();
      let dateEnd   = DateTime().add(1, 'day'); // TODO: test
      switch (_duration){
        case 'day'    : dateStart = dateStart.subtract(1, 'day');    break;
        case 'week'   : dateStart = dateStart.subtract(7, 'day');    break;
        case 'month'  : dateStart = dateStart.subtract(1, 'month');  break;
      }
      
      RequestManager.Dashboard.getStats({
        start_date: dateStart.format('YYYY-MM-DD'),
        end_date  : dateEnd.format('YYYY-MM-DD')
      }).then( (res) => {
        /*
          {type: "club", count: 1}
          {type: "event", count: 1}
          {type: "trainer", count: 4}
          {type: "user", count: 2}
         */
        this.statistic[_duration] = {
          club    : 0,
          event   : 0,
          trainer : 0,
          user    : 0,
        };
        res.map((el) => {
          this.statistic[_duration][el.type] = el.count;
          return true;
        });
        
      });
    },
    load(){
      this.loadStats(this.duration);
      
      RequestManager.Dashboard.getLastTransaction({}).then( (res) => {
        this.paymentList = res.results.map((transaction) => {
          return {
            status: 'Оплачено',
            name: transaction.user.first_name + ' ' + transaction.user.last_name,
            date: DateTime(transaction.created_at).utc().format('DD.MM.YYYY HH:MM'), // 2021-03-23T00:00:00Z => '05.02.2021 21:54'
          };
        });
      });
      RequestManager.Dashboard.getLastRegistered({}).then( (res) => {
        this.registrationList = res.results.map((user) => {
          return {
            email: user.username, // 'clara.lyons@aol.com',
            payer: user.system_role || 'user', // 'club'
          };
        });
      });
      RequestManager.Dashboard.getLastClub({}).then( (res) => {
        this.clubList = res.results.map((club) => {
          return {
            id    : club.id,
            satus : club.is_active,
            img   : club.avatar,
            name  : club.name,
            text  : club.about,
            tags  : club.tags.map((tag) => { return tag.tag; })
          };
        });
      });
    },
  },
  created() {
    this.load();
  }
};
</script>

<style lang="scss" scoped>

// dashboard
.dashboard {
  .dashboard__title {
    margin-bottom: 16px;
    font-size: 17px;
  }
  .dashboard__duration {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .dashboard__label {
      margin-right: 12px;
      font-size: 22px;
      font-weight: 400;
    }
    ::v-deep .form-group {
      padding: 0;
      margin: 0;
    }
  }
  .dashboard__status {
    display: flex;
    margin: 0 -8px;
    .dashboard__status-ell {
      width: 25%;
      padding: 0 8px;
    }
  }
  .table {
    margin-bottom: 30px;
  }
}

// status-block
.status-block {
  display: flex;
  align-items: center;
  padding: 12px 14px;
  border-radius: 8px;
  background-color: var(--br-block-sub);
  .status-block__icon {
    flex-shrink: 0;
    margin-right: 12px;
  }
  .status-block__content {
    .status-block__val {
      color: var(--text-prime);
      font-size: 32px;
    }
    .status-block__prop {
      color: var(--text-sub);
      font-size: 14px;
    }
  }
}

</style>

<template>
  <div :class="['card-club', {'card-club--active': satus }]">
    <div class="card-club__body">
      <div class="card-club__img" v-if="img">
        <img :src="img" alt="photo">
      </div>
      <div class="card-club__content">
        <div class="card-club__head">
          <span class="card-club__status"></span>
          <div class="card-club__name">
            {{ name }} <span v-if="club" class="card-club__name-club">( {{ club }} )</span>
          </div>
        </div>
        <div class="card-club__text">{{ text }}</div>
      </div>
      <div class="card-club__control">
        <slot name="control"></slot>
      </div>
    </div>
    <div class="card-club__tags">
      <template v-for="(item, index) in tags">
        <div v-if="item" class="card-club__tags-ell" :key="index">{{ item }}</div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: "CardClub",
  props: {
    img: String,
    satus: Boolean,
    name: String,
    club: String,
    text: String,
    tags: Array,
  }
};
</script>

<style lang="scss" scoped>

// card-club
.card-club {
  position: relative;
  padding: 12px 16px 6px;
  background-color: var(--br-block-prime);
  border-radius: 8px;
  opacity: 0.6;
  .card-club__body {
    display: flex;
    margin-bottom: 10px;
    .card-club__img {
      flex-shrink: 0;
      width: 72px;
      height: 72px;
      margin-right: 15px;
      border-radius: 4px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .card-club__content {
      .card-club__head {
        display: flex;
        margin-bottom: 4px;
      }
      .card-club__status {
        display: block;
        width: 8px;
        height: 8px;
        margin-top: 5px;
        margin-right: 6px;
        border-radius: 50%;
        background-color: var(--color-red);
      }
      .card-club__name {
        color: var(--text-sub);
        font-size: 17px;
        .card-club__name-club {
          color: var(--text-sub);
          font-size: 14px;
        }
      }
      .card-club__text {
        color: var(--text-sub);
        font-size: 14px;
      }
    }
  }
  .card-club__tags {
    display: flex;
    flex-wrap: wrap;
    .card-club__tags-ell {
      display: block;
      margin-bottom: 4px;
      margin-right: 4px;
      padding: 6px 8px 4px;
      color: var(--color-prime);
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      border: 1px solid #777971;
      border-radius: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .card-club__control {
    display: flex;
    position: absolute;
    top: 8px;
    right: 8px;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-in-out;
    z-index: 10;
    svg {
      margin-right: 8px;
      cursor: pointer;
      path {
        fill: var(--color-prime);
        transition: all .2s ease-in-out;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        path {
          fill: var(--color-sub);
        }
      }
    }
  }
  &:hover {
    .card-club__control {
      opacity: 1;
      visibility: visible;
    }
  }
  &--active {
    opacity: 1;
    .card-club__body {
      .card-club__content {
        .card-club__status {
          background-color: var(--color-green);
        }
        .card-club__name {
          color: var(--text-prime);
        }
      }
    }
  }
}

</style>
